<template>
  <div class="menus-wrapper">
    <a-menu
      mode="horizontal"
      :default-selected-keys="['home']"
      v-model="currentMenu"
      :style="{
        maxHeight: '64px',
        borderRadius: '5px'
      }"
    >

      <!-- 首页 -->
      <a-menu-item key="home">
        <router-link to="/" class="parent-menu-title">
          <a-icon type="home" />
          面板
        </router-link>
      </a-menu-item>

      <!-- 运营商 -->
      <a-sub-menu v-if="isShowMenu('menu:carriers')">
        <span
          slot="title"
          class="parent-menu-title"
        >
          <a-icon type="fork" />供应链
          <a-icon type="down" :style="{fontSize: '11px'}" />
        </span>

        <a-menu-item key="carrier_accounts" v-if="isShowMenu('carrier_accounts')">
          <router-link to="/carrier_accounts">运营商</router-link>
        </a-menu-item>

        <a-menu-item key="base_carrier_products" v-if="isShowMenu('base_carrier_products')">
          <router-link to="/base_carrier_products">采购套餐</router-link>
        </a-menu-item>
      </a-sub-menu>

      <!-- 资源管理 -->
      <a-sub-menu v-if="isShowMenu('menu:inventories')">
        <span
          slot="title"
          class="parent-menu-title"
        >
          <a-icon type="hdd" />资源管理
          <a-icon type="down" :style="{fontSize: '11px'}" />
        </span>

        <a-menu-item key="sim_card_inventories" v-if="isShowMenu('sim_card_inventories')">
          <router-link to="/sim_card_inventories">资源入库</router-link>
        </a-menu-item>
      </a-sub-menu>

      <!-- 销售管理 -->
      <a-sub-menu v-if="isShowMenu('menu:markets')">
        <span
          slot="title"
          class="parent-menu-title"
        >
          <a-icon type="shopping" />业务管理
          <a-icon type="down" :style="{fontSize: '11px'}" />
        </span>

        <a-menu-item key="agents" v-if="isShowMenu('agents')">
          <router-link to="/agents">客户管理</router-link>
        </a-menu-item>

        <a-menu-item key="base_products" v-if="isShowMenu('base_products')">
          <router-link to="/base_products">上架套餐</router-link>
        </a-menu-item>

        <a-menu-item key="agent_purchase_orders" v-if="isShowMenu('agent_purchase_orders')">
          <router-link to="/agent_purchase_orders">订单管理</router-link>
        </a-menu-item>

        <a-menu-item key="allocation_records" v-if="isShowMenu('allocation_records')">
          <router-link to="/allocation_records">划拨批次记录</router-link>
        </a-menu-item>
      </a-sub-menu>

      <!-- 报表统计 -->
      <a-sub-menu v-if="isShowMenu('menu:statistics')">
        <span
          slot="title"
          class="parent-menu-title"
        >
          <a-icon type="bar-chart" />运营管理
          <a-icon type="down" :style="{fontSize: '11px'}" />
        </span>

        <a-menu-item key="sale_monthly_performances" v-if="isShowMenu('sale_monthly_performances')">
          <router-link to="/sale_monthly_performances">销售业绩</router-link>
        </a-menu-item>
        <a-menu-item key="agent_statistics" v-if="isShowMenu('agent_statistics')">
          <router-link to="/agent_statistics_finance">客户统计</router-link>
        </a-menu-item>
      </a-sub-menu>

      <!-- 工作事项 -->
      <a-sub-menu v-if="isShowMenu('menu:work_items')">
        <span
          slot="title"
          class="parent-menu-title"
        >
          <a-icon type="schedule" />工作事项
          <a-icon type="down" :style="{fontSize: '11px'}" />
        </span>
        <a-menu-item key="registered_agents" v-if="isShowMenu('registered_agents')">
          <router-link to="/registered_agents">注册账号审核</router-link>
        </a-menu-item>
        <a-menu-item key="announcements" v-if="isShowMenu('announcements')">
          <router-link to="/announcements">公告管理</router-link>
        </a-menu-item>
        <a-menu-item key="cancel_card_manages" v-if="isShowMenu('cancel_card_manages')">
          <router-link to="/cancel_card_manages">销卡管理</router-link>
        </a-menu-item>
        <a-menu-item key="card_service_manages" v-if="isShowMenu('card_service_manages')">
          <router-link to="/card_service_manages_unexpired">卡服务周期管理</router-link>
        </a-menu-item>
        <a-menu-item key="card_silent_manages" v-if="isShowMenu('card_silent_manages')">
          <router-link to="/card_silent_manages_unexpired">卡沉默期管理</router-link>
        </a-menu-item>
        <a-menu-item key="customer_service_manages" v-if="isShowMenu('customer_service_manages')">
          <router-link to="/customer_service_manages_issue">售后管理</router-link>
        </a-menu-item>
      </a-sub-menu>

      <!-- 系统查询 -->
      <a-sub-menu v-if="isShowMenu('menu:queries')">
        <span
          slot="title"
          class="parent-menu-title"
        >
          <a-icon type="eye" />系统查询
          <a-icon type="down" :style="{fontSize: '11px'}" />
        </span>

        <a-menu-item key="batch_operation_records" v-if="isShowMenu('batch_operation_records')">
          <router-link to="/batch_operation_records">批量操作查询</router-link>
        </a-menu-item>

        <a-menu-item key="sim_card_carrier_tasks" v-if="isShowMenu('sim_card_carrier_tasks')">
          <router-link to="/sim_card_carrier_tasks">接口调用查询</router-link>
        </a-menu-item>
      </a-sub-menu>

      <!-- 设置 -->
      <a-sub-menu v-if="isShowMenu('menu:settings')">
        <span
          slot="title"
          class="parent-menu-title"
        >
          <a-icon type="setting" />平台管理
          <a-icon type="down" :style="{fontSize: '11px'}" />
        </span>

        <a-menu-item key="roles" v-if="isShowMenu('roles')">
          <router-link to="/roles">角色管理</router-link>
        </a-menu-item>

        <a-menu-item key="users" v-if="isShowMenu('users')">
          <router-link to="/users">用户管理</router-link>
        </a-menu-item>

        <a-menu-item key="user_business_type_statistics" v-if="isShowMenu('user_business_type_statistics')">
          <router-link to="/user_business_type_statistics">用户业务模式管理</router-link>
        </a-menu-item>

        <a-menu-item key="api_interfaces" v-if="isShowMenu('api_interfaces')">
          <router-link to="/api_interfaces">平台API管理</router-link>
        </a-menu-item>

        <a-menu-item key="sms_channels" v-if="false">
          <router-link to="/sms_channels">短信接口管理</router-link>
        </a-menu-item>

        <a-menu-item key="sms_templates" v-if="false">
          <router-link to="/sms_templates">短信模版管理</router-link>
        </a-menu-item>

        <a-menu-item key="agreements" v-if="isShowMenu('agreements')">
          <router-link to="/agreements">协议管理</router-link>
        </a-menu-item>

        <a-menu-item key="card_types" v-if="isShowMenu('card_types')">
          <router-link to="/card_types">卡板管理</router-link>
        </a-menu-item>

        <a-menu-item key="industry_types" v-if="isShowMenu('industry_types')">
          <router-link to="/industry_types">行业管理</router-link>
        </a-menu-item>

        <a-menu-item key="device_types" v-if="isShowMenu('device_types')">
          <router-link to="/device_types">设备管理</router-link>
        </a-menu-item>

        <a-menu-item key="contracting_parties" v-if="isShowMenu('contracting_parties')">
          <router-link to="/contracting_parties">签约主体</router-link>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'

export default {
  name: 'HeaderMenu',
  computed: {
    currentMenu: {
      get() {
        return this.$store.getters.currentMenu
      },
      set(val) {
        // setActiveMenu 中设置
        // 这里给一个空的 setter, 解决在新标签打开菜单报错问题
      }
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },
    readyToGo() {
      this.$warning({
        title: '建设中...',
        content: ''
      })
    }
  }
}
</script>

<style lang="less" scoped>
.menus-wrapper {
  /deep/ .ant-menu-submenu-title {
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
.menus-wrapper {
  z-index: 1;
  width: 100%;
  padding: 16px 50px 0 50px;

  ul {
    display: flex;
    padding: 13px 10px;
    -webkit-box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  }

  li {
    border-bottom: none;
    padding: 0 5px;

    &:hover {
      border: none;

      .parent-menu-title {
        background-color: #f8f8f8;
      }
    }

    .parent-menu-title {
      border-radius: 4px;
      height: 39px;
      display: flex;
      align-items: center;
      padding: 0 20px;
    }
  }

  .ant-menu-item-selected, .ant-menu-submenu-selected {
    .parent-menu-title {
      background: #46a6ff;
      color: #fff;
      background: linear-gradient(
          118deg, #1177e5, rgba(32, 3, 197, 0.7));
      -webkit-box-shadow: 0 0 6px 1px rgb(115 103 240 / 60%);
      box-shadow: 0 0 6px 1px rgb(115 103 240 / 60%);
    }
  }
}
</style>
