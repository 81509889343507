import BasicLayout from '@/layouts/BasicLayout'
import CustomStyleLayout from '@/layouts/CustomStyleLayout'

// meta 部分说明
// 如 meta: { title: '用户管理', permission: 'users', menu: 'users' }
// permission 用于权限控制，api 返回的权限列表中有 users 才可以访问
// menu       用于菜单选中高亮，如果不是菜单则可以不传值，值需要和 a-menu-item key="users" 的key值相同
// title      用于浏览器 tab 显示以及面包屑显示
//            - 以 ':' 开头的 title 为动态的值占位符, 用作业务逻辑中的值替换
//              可使用 SetDynamicBreadCrumbsPlaceholder 方法替换占位符的值, 如：
//              this.$store.dispatch('SetDynamicBreadCrumbsPlaceholder', { ':agent_name': '客户1' })
//            - 还提供了 ConcatDynamicBreadCrumbs 方法追加面包屑, 常用于面包屑生成规则不统一，自行生成面包屑

// 异步路由(需要走权限控制)
export const asyncRouter = [
  // example
  {
    path: '/carrier_accounts',
    component: BasicLayout,
    meta: { title: '运营商账户', menu: 'carrier_accounts' },
    children: [
      {
        path: '',
        name: 'carrier_accounts',
        component: () => import('@/views/carrier_accounts/index.vue'),
        meta: { permission: 'carrier_accounts' }
      },
      {
        path: ':id',
        component: () => import('@/views/carrier_accounts/ShowTab.vue'),
        meta: { title: ':carrier_account_name' },
        children: [
          {
            path: 'info',
            name: 'carrier_account_info',
            component: () => import('@/views/carrier_accounts/Show.vue'),
            meta: { permission: 'carrier_accounts/show' }
          },
          {
            path: 'products',
            name: 'carrier_account_products',
            component: () => import('@/views/carrier_products/index.vue'),
            meta: { permission: 'carrier_products' }
          },
          {
            path: 'sim_cards',
            name: 'carrier_sim_cards',
            component: () => import('@/views/sim_cards/carrier/index'),
            meta: { permission: 'carrier_sim_cards' }
          },
          {
            path: 'sms_templates',
            name: 'carrier_sms_templates',
            component: () => import('@/views/sms_templates/carrier/index'),
            meta: { permission: 'carrier_sms_templates' }
          }
        ]
      }
    ]
  },
  {
    path: '/switch_user',
    name: 'switch_user',
    component: () => import('@/views/switch_user/index')
  },
  {
    path: '/sale_monthly_performances',
    component: BasicLayout,
    meta: { title: '销售业绩', menu: 'sale_monthly_performances' },
    children: [
      {
        path: '',
        name: 'sale_monthly_performances',
        component: () => import('@/views/sale_monthly_performances/index'),
        meta: { permission: 'sale_monthly_performances' }
      },
      {
        path: 'by_month',
        name: 'sale_monthly_performance_by_month',
        component: () => import('@/views/sale_monthly_performances/admin/ByMonthList'),
        meta: { permission: 'sale_monthly_performances/by_month', title: ':month' }
      }
    ]
  },
  {
    path: '/sale_monthly_performances',
    component: CustomStyleLayout,
    meta: { title: '销售业绩', menu: 'sale_monthly_performances' },
    children: [
      {
        path: ':id',
        name: 'sale_monthly_performance_info',
        component: () => import('@/views/sale_monthly_performances/Show'),
        meta: { permission: 'sale_monthly_performances/show' }
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/agent_statistics/ShowTab.vue'),
        meta: { title: '客户统计' },
        children: [
          {
            path: 'agent_statistics_finance',
            name: 'agent_statistics_finance',
            component: () => import('@/views/agent_statistics/Finance.vue'),
            meta: { permission: 'agent_statistics' }
          },
          {
            path: 'agent_statistics_order',
            name: 'agent_statistics_order',
            component: () => import('@/views/agent_statistics/Order.vue'),
            meta: { permission: 'agent_statistics' }
          },
          {
            path: 'agent_statistics_card',
            name: 'agent_statistics_card',
            component: () => import('@/views/agent_statistics/Card.vue'),
            meta: { permission: 'agent_statistics' }
          }
        ]
      }
    ]
  },
  {
    path: '/sim_card_inventories',
    component: BasicLayout,
    meta: { title: '库存卡管理', menu: 'sim_card_inventories' },
    children: [
      {
        path: '',
        name: 'sim_card_inventories',
        component: () => import('@/views/sim_card_inventories/index.vue'),
        meta: { permission: 'sim_card_inventories' }
      }
    ]
  },
  {
    path: '/batch_operation_records',
    component: BasicLayout,
    meta: { title: '批量操作查询', menu: 'batch_operation_records' },
    children: [
      {
        path: '',
        name: 'batch_operation_records',
        component: () => import('@/views/batch_operation_records/index.vue'),
        meta: { permission: 'batch_operation_records' }
      }
    ]
  },
  {
    path: '/batch_operation_records',
    component: CustomStyleLayout,
    meta: { title: '批量操作查询', menu: 'batch_operation_records' },
    children: [
      {
        path: ':id/info',
        name: 'batch_operation_record_info',
        component: () => import('@/views/batch_operation_records/detail/index.vue'),
        meta: { title: '批量操作详情', permission: 'batch_operation_records/show' }
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/sim_card_carrier_tasks/ShowTab.vue'),
        meta: { title: '接口调用查询' },
        children: [
          {
            path: 'sim_card_carrier_tasks',
            name: 'sim_card_carrier_tasks',
            component: () => import('@/views/sim_card_carrier_tasks/index.vue'),
            meta: { menu: 'sim_card_carrier_tasks', permission: 'sim_card_carrier_tasks' }
          }
        ]
      }
    ]
  },
  {
    path: '/card_types',
    component: BasicLayout,
    meta: { title: '卡板管理', menu: 'card_types' },
    children: [
      {
        path: '',
        name: 'card_types',
        component: () => import('@/views/card_types/index.vue'),
        meta: { permission: 'card_types' }
      }
    ]
  },
  {
    path: '/industry_types',
    component: BasicLayout,
    meta: { title: '行业管理', menu: 'industry_types' },
    children: [
      {
        path: '',
        name: 'industry_types',
        component: () => import('@/views/industry_types/index.vue'),
        meta: { permission: 'industry_types' }
      }
    ]
  },
  {
    path: '/device_types',
    component: BasicLayout,
    meta: { title: '设备管理', menu: 'device_types' },
    children: [
      {
        path: '',
        name: 'device_types',
        component: () => import('@/views/device_types/index.vue'),
        meta: { permission: 'device_types' }
      }
    ]
  },
  {
    path: '/agents',
    component: BasicLayout,
    meta: { title: '客户列表', menu: 'agents' },
    children: [
      {
        path: '',
        name: 'agents',
        component: () => import('@/views/agents/index.vue'),
        meta: { permission: 'agents' }
      },
      {
        path: ':id',
        component: () => import('@/views/agents/ShowTab.vue'),
        meta: { title: ':agent_name' },
        children: [
          {
            path: '',
            component: () => import('@/views/agents/info/Tab.vue'),
            children: [
              {
                path: 'info',
                name: 'agent_info',
                component: () => import('@/views/agents/info/index.vue'),
                meta: { permission: 'agents/show' }
              },
              {
                path: 'setting',
                name: 'agent_setting',
                component: () => import('@/views/agents/info/Setting.vue'),
                meta: { permission: 'agents/setting' }
              },
              {
                path: 'vpdn_manage',
                name: 'agent_vpdn_manage',
                component: () => import('@/views/agents/info/vpdn_manage/index.vue'),
                meta: { permission: 'agents/vpdn_manage' }
              },
              {
                path: 'vpdn_manage/:vpdn_manage_id',
                name: 'agent_vpdn_manage_info',
                component: () => import('@/views/agents/info/vpdn_manage/Detail.vue'),
                meta: { permission: 'agents/vpdn_manage' }
              },
              {
                path: 'api_info',
                name: 'agent_api_info',
                component: () => import('@/views/agents/info/Api'),
                meta: { permission: 'agents/api_info' }
              },
              {
                path: 'enterprise_qualification',
                name: 'agent_enterprise_qualification',
                component: () => import('@/views/enterprise_qualifications/index.vue'),
                meta: { permission: 'agents/enterprise_qualification' }
              },
              {
                path: 'receive_addresses',
                name: 'agent_receive_addresses',
                component: () => import('@/views/receive_addresses/index.vue'),
                meta: { permission: 'agents/receive_address' }
              },
              {
                path: 'agent_files',
                name: 'agent_files',
                component: () => import('@/views/agent_files/index.vue'),
                meta: { permission: 'agent_files' }
              },
              {
                path: 'customize_site',
                name: 'agent_customize_site',
                component: () => import('@/views/customize_site/index.vue'),
                meta: { permission: 'agents/customize_site' }
              }
            ]
          },
          {
            path: 'products',
            name: 'agent_products',
            component: () => import('@/views/agents_products/index.vue'),
            meta: { permission: 'agents_products' }
          },
          {
            path: 'sim_cards',
            name: 'agent_sim_cards',
            component: () => import('@/views/sim_cards/agent/index'),
            meta: { permission: 'sim_cards' }
          },
          {
            path: '',
            component: () => import('@/views/agents/finance/Tab'),
            children: [
              {
                path: 'finance',
                name: 'agent_finance',
                component: () => import('@/views/agents/finance/Overview'),
                meta: { permission: 'agents/finance' }
              },
              {
                path: 'monthly_bills',
                name: 'agent_monthly_bills',
                component: () => import('@/views/agent_monthly_bills/index'),
                meta: { permission: 'agent_monthly_bills' }
              },
              {
                path: 'agent_consumption_bills',
                name: 'agent_consumption_bills',
                component: () => import('@/views/agent_consumption_bills/index'),
                meta: { permission: 'agent_consumption_bills' }
              },
              {
                path: 'transfers',
                name: 'agent_transfers',
                component: () => import('@/views/transfers/agent/index'),
                meta: { permission: 'agents/transfers' }
              },
              {
                path: 'balance_statistics',
                name: 'agent_balance_statistics',
                component: () => import('@/views/balance_statistics/agent/index'),
                meta: { permission: 'agents/balance_statistics' }
              },
              {
                path: 'monthly_bills/:agent_monthly_bill_id',
                name: 'agent_monthly_bill_info',
                component: () => import('@/views/agent_monthly_bills/Show'),
                meta: { permission: 'agent_monthly_bills' }
              },
              {
                path: 'card_use/:card_use_id',
                name: 'agent_monthly_bill_card_use_info',
                component: () => import('@/views/agent_monthly_bills/card_use/show/index'),
                meta: { permission: 'agent_monthly_bills' }
              },
              {
                path: 'agent_renew_order/:renew_order_id',
                name: 'agent_monthly_bill_renew_order_info',
                component: () => import('@/views/agent_monthly_bills/renew/show/index'),
                meta: { permission: 'agent_monthly_bills' }
              },
              {
                path: 'stop/:stop_id',
                name: 'agent_monthly_bill_stop_info',
                component: () => import('@/views/agent_monthly_bills/stop/show/index'),
                meta: { permission: 'agent_monthly_bills' }
              },
              {
                path: 'agent_consumption_bills/renew_orders/:renew_order_id',
                name: 'agent_consumption_bill_renew_order_info',
                component: () => import('@/views/agent_consumption_bills/renew/show/index'),
                meta: { permission: 'agent_consumption_bills' }
              },
              {
                path: 'agent_active_order/:active_order_id',
                name: 'agent_monthly_bill_active_order_info',
                component: () => import('@/views/agent_monthly_bills/active/show/index'),
                meta: { permission: 'agent_monthly_bills' }
              },
              {
                path: 'agent_consumption_bills/active_orders/:active_order_id',
                name: 'agent_consumption_bill_active_order_info',
                component: () => import('@/views/agent_consumption_bills/active/show/index'),
                meta: { permission: 'agent_consumption_bills' }
              },
              {
                path: 'agent_subscribe_order/:subscribe_order_id',
                name: 'agent_monthly_bill_subscribe_order_info',
                component: () => import('@/views/agent_monthly_bills/subscribe/show/index'),
                meta: { permission: 'agent_monthly_bills' }
              },
              {
                path: 'agent_consumption_bills/subscribe_orders/:subscribe_order_id',
                name: 'agent_consumption_bill_subscribe_order_info',
                component: () => import('@/views/agent_consumption_bills/subscribe/show/index'),
                meta: { permission: 'agent_consumption_bills' }
              }
            ]
          },
          {
            path: '',
            component: () => import('@/views/agents/commission/Tab'),
            children: [
              {
                path: 'commission',
                name: 'agent_commission',
                component: () => import('@/views/agents/commission/Overview'),
                meta: { permission: 'agents/finance' }
              },
              {
                path: 'monthly_bills_commission',
                name: 'agent_monthly_bills_commission',
                component: () => import('@/views/agent_monthly_bills_commission/index'),
                meta: { permission: 'agent_monthly_bills' }
              },
              {
                path: 'withdrawals',
                name: 'agent_withdrawals',
                component: () => import('@/views/withdrawals/agent/index'),
                meta: { permission: 'agents/withdrawals' }
              },
              {
                path: 'commission_statistics',
                name: 'agent_commission_statistics',
                component: () => import('@/views/commission_statistics/agent/index'),
                meta: { permission: 'agents/balance_statistics' }
              },
              {
                path: 'monthly_bills_commission/:agent_monthly_bill_id',
                name: 'agent_monthly_bill_info_commission',
                component: () => import('@/views/agent_monthly_bills_commission/Show'),
                meta: { permission: 'agent_monthly_bills' }
              },
              {
                path: 'card_use_commission/:card_use_id',
                name: 'agent_monthly_bill_card_use_info_commission',
                component: () => import('@/views/agent_monthly_bills_commission/card_use/show/index'),
                meta: { permission: 'agent_monthly_bills' }
              },
              {
                path: 'agent_renew_order_commission/:renew_order_id',
                name: 'agent_monthly_bill_renew_order_info_commission',
                component: () => import('@/views/agent_monthly_bills_commission/renew/show/index'),
                meta: { permission: 'agent_monthly_bills' }
              },
              {
                path: 'agent_subscribe_order_commission/:subscribe_order_id',
                name: 'agent_monthly_bill_subscribe_order_info_commission',
                component: () => import('@/views/agent_monthly_bills_commission/subscribe/show/index'),
                meta: { permission: 'agent_monthly_bills' }
              },
              {
                path: 'agent_active_order_commission/:active_order_id',
                name: 'agent_monthly_bill_active_order_info_commission',
                component: () => import('@/views/agent_monthly_bills_commission/active/show/index'),
                meta: { permission: 'agent_monthly_bills' }
              }
            ]
          },
          {
            path: 'purchase_orders',
            name: 'agent_self_purchase_orders',
            component: () => import('@/views/agent_purchase_orders/agent/index'),
            meta: { permission: 'agents/purchase_orders' }
          },
          {
            path: 'traffic_pools',
            name: 'agent_traffic_pools',
            component: () => import('@/views/traffic_pools/index')
          },
          {
            path: 'traffic_pools/:traffic_pool_id',
            component: () => import('@/views/traffic_pools/show/Tab'),
            children: [
              {
                path: 'info',
                name: 'agent_traffic_pool_info',
                component: () => import('@/views/traffic_pools/show/index')
              },
              {
                path: 'sim_cards',
                name: 'agent_traffic_pool_sim_cards',
                component: () => import('@/views/traffic_pools/sim_card/index')
              },
              {
                path: 'expand_records',
                name: 'agent_traffic_pool_expand_records',
                component: () => import('@/views/traffic_pools/expand_records/index')
              },
              {
                path: 'usage_orders',
                name: 'agent_traffic_pool_usage_orders',
                component: () => import('@/views/traffic_pools/usage_orders/index')
              }
            ]
          },
          {
            path: '',
            component: () => import('@/views/sim_cards/service_cycle_manage/Tab'),
            children: [
              {
                path: 'service_cycle_unexpired',
                name: 'service_cycle_unexpired',
                component: () => import('@/views/sim_cards/service_cycle_manage/service_cycle_unexpired/index'),
                meta: { permission: 'sim_cards/service_cycle_manage' }
              },
              {
                path: 'service_cycle_expired',
                name: 'service_cycle_expired',
                component: () => import('@/views/sim_cards/service_cycle_manage/service_cycle_expired/index'),
                meta: { permission: 'sim_cards/service_cycle_manage' }
              },
              {
                path: 'current_month_expect_cancel',
                name: 'current_month_expect_cancel',
                component: () => import('@/views/sim_cards/service_cycle_manage/current_month_expect_cancel/index'),
                meta: { permission: 'sim_cards/service_cycle_manage' }
              },
              {
                path: 'renewed',
                name: 'service_cycle_renewed',
                component: () => import('@/views/sim_cards/service_cycle_manage/renewed/index'),
                meta: { permission: 'sim_cards/service_cycle_manage' }
              },
              {
                path: 'canceled',
                name: 'service_cycle_canceled',
                component: () => import('@/views/sim_cards/service_cycle_manage/canceled/index'),
                meta: { permission: 'sim_cards/service_cycle_manage' }
              }
            ]
          },
          {
            path: 'agent_sms_templates',
            name: 'agent_sms_templates',
            component: () => import('@/views/agents_sms_templates/index.vue'),
            meta: { permission: 'agent_sms_templates' }
          },
          {
            path: 'agent_agencies',
            name: 'agent_agencies',
            component: () => import('@/views/agents/agencies/index.vue'),
            meta: { permission: 'agent_agencies' }
          }
        ]
      }
    ]
  },
  {
    path: '/base_products',
    component: BasicLayout,
    meta: { title: '基础套餐', menu: 'base_products' },
    children: [
      {
        path: '',
        name: 'base_products',
        component: () => import('@/views/base_products/index'),
        meta: { permission: 'base_products' }
      }
    ]
  },
  {
    path: '/agent_purchase_orders',
    component: CustomStyleLayout,
    meta: { title: '订单管理', menu: 'agent_purchase_orders' },
    children: [
      {
        path: '',
        name: 'agent_purchase_orders',
        component: () => import('@/views/agent_purchase_orders/index'),
        meta: { permission: 'agent_purchase_orders' }
      }
    ]
  },
  {
    path: '/allocation_records',
    component: BasicLayout,
    meta: { title: '划拨批次记录', menu: 'allocation_records' },
    children: [
      {
        path: '',
        name: 'allocation_records',
        component: () => import('@/views/allocation_records/index'),
        meta: { permission: 'allocation_records' }
      }
    ]
  },
  {
    path: '/allocation_records',
    component: CustomStyleLayout,
    meta: { title: '划拨批次记录', menu: 'allocation_records' },
    children: [
      {
        path: ':id/info',
        name: 'allocation_record_info',
        component: () => import('@/views/allocation_records/detail/index.vue'),
        meta: { title: '划拨批次详情', permission: 'allocation_records/show' }
      }
    ]
  },
  {
    path: '/roles',
    component: BasicLayout,
    meta: { title: '角色与权限', menu: 'roles' },
    children: [
      {
        path: '',
        name: 'roles',
        component: () => import('@/views/roles/index.vue'),
        meta: { permission: 'roles' }
      }
    ]
  },
  {
    path: '/users',
    component: BasicLayout,
    meta: { title: '用户管理', menu: 'users' },
    children: [
      {
        path: '',
        name: 'users',
        component: () => import('@/views/users/index.vue'),
        meta: { permission: 'users' }
      }
    ]
  },
  {
    path: '/base_carrier_products',
    component: BasicLayout,
    meta: { title: '运营商通用套餐', menu: 'base_carrier_products' },
    children: [
      {
        path: '',
        name: 'base_carrier_products',
        component: () => import('@/views/base_carrier_products/index'),
        meta: { permission: 'base_carrier_products' }
      }
    ]
  },
  {
    path: '/user_business_type_statistics',
    component: BasicLayout,
    meta: { title: '用户业务模式管理', menu: 'user_business_type_statistics' },
    children: [
      {
        path: '',
        name: 'user_business_type_statistics',
        component: () => import('@/views/user_business_type_statistics/index.vue'),
        meta: { permission: 'user_business_type_statistics' }
      }
    ]
  },
  {
    path: '/agreements',
    component: BasicLayout,
    meta: { title: '协议管理', menu: 'agreements' },
    children: [
      {
        path: '',
        name: 'agreements',
        redirect: 'platform_service',
        component: () => import('@/views/agreements/Tab'),
        meta: { permission: 'agreements' },
        children: [
          {
            path: 'platform_service',
            name: 'platform_service_agreements',
            component: () => import('@/views/agreements/platform_service/index.vue'),
            meta: { permission: 'agreements/platform_service' }
          },
          {
            path: 'card',
            name: 'card_agreements',
            component: () => import('@/views/agreements/card/index.vue'),
            meta: { permission: 'agreements/card' }
          }
        ]
      }
    ]
  },
  {
    path: '/api_interfaces',
    component: BasicLayout,
    meta: { title: '平台API管理', menu: 'api_interfaces' },
    children: [
      {
        path: '',
        name: 'api_interfaces',
        component: () => import('@/views/api_interfaces/index.vue'),
        meta: { permission: 'api_interfaces' }
      }
    ]
  },
  {
    path: '/sms_channels',
    component: BasicLayout,
    meta: { title: '短信接口管理', menu: 'sms_channels' },
    children: [
      {
        path: '',
        name: 'sms_channels',
        component: () => import('@/views/sms_channels/index.vue'),
        meta: { permission: 'sms_channels' }
      }
    ]
  },
  {
    path: '/sms_templates',
    component: BasicLayout,
    meta: { title: '短信模版管理', menu: 'sms_templates' },
    children: [
      {
        path: '',
        name: 'sms_templates',
        component: () => import('@/views/sms_templates/index.vue'),
        meta: { permission: 'sms_templates' }
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { title: '公告管理', menu: 'announcements' },
    children: [
      {
        path: 'announcements',
        name: 'announcements',
        component: () => import('@/views/announcements/index.vue'),
        meta: { permission: 'announcements' }
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { title: '通知消息', menu: '' },
    children: [
      {
        path: 'notifications',
        name: 'notifications',
        component: () => import('@/views/notifications/index.vue')
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { title: '导出管理', menu: '' },
    children: [
      {
        path: 'data_export_tasks',
        name: 'data_export_tasks',
        component: () => import('@/views/data_export_tasks/index.vue')
      }
    ]
  },
  {
    path: '/contracting_parties',
    component: BasicLayout,
    meta: { title: '签约主体', menu: 'contracting_parties' },
    children: [
      {
        path: '',
        name: 'contracting_parties',
        component: () => import('@/views/contracting_parties/index.vue'),
        meta: { permission: 'contracting_parties' }
      }
    ]
  },
  {
    path: '/registered_agents',
    component: BasicLayout,
    meta: { title: '注册账号审核', menu: 'registered_agents' },
    children: [
      {
        path: '',
        name: 'registered_agents',
        component: () => import('@/views/registered_agents/index'),
        meta: { permission: 'registered_agents' }
      },
      {
        path: ':id',
        name: 'registered_agent_info',
        component: () => import('@/views/registered_agents/Show'),
        meta: { title: ':registered_agent_name', permission: 'registered_agents' }
      }
    ]
  },
  {
    path: '/user',
    name: 'user',
    component: BasicLayout,
    children: [
      {
        path: ':id',
        name: 'user_info',
        component: () => import('@/views/users/personal_info/index'),
        meta: { title: '个人资料', permission: 'users/edit_self_info' }
      }
    ]
  },
  {
    path: '/cancel_card_manages',
    component: BasicLayout,
    meta: { title: '销卡管理', menu: 'cancel_card_manages' },
    children: [
      {
        path: '',
        name: 'cancel_card_manages',
        component: () => import('@/views/cancel_card_manages/index.vue'),
        meta: { permission: 'cancel_card_manages' }
      }
    ]
  },
  {
    path: '/cancel_card_manages',
    component: CustomStyleLayout,
    meta: { title: '销卡管理', menu: 'cancel_card_manages' },
    children: [
      {
        path: 'carrier_accounts/:id',
        name: 'carrier_accounts_expect_cancel_cards',
        component: () => import('@/views/cancel_card_manages/expect_cancel_card_list/index.vue'),
        meta: { title: ':carrier_account_name', permission: 'cancel_card_manages' }
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/card_service_manages/ShowTab.vue'),
        meta: { title: '卡服务周期管理' },
        children: [
          {
            path: 'card_service_manages_unexpired',
            name: 'card_service_manages_unexpired',
            component: () => import('@/views/card_service_manages/Unexpired.vue'),
            meta: { permission: 'card_service_manages' }
          },
          {
            path: 'card_service_manages_expired',
            name: 'card_service_manages_expired',
            component: () => import('@/views/card_service_manages/Expired.vue'),
            meta: { permission: 'card_service_manages' }
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/card_silent_manages/ShowTab.vue'),
        meta: { title: '卡沉默期管理' },
        children: [
          {
            path: 'card_silent_manages_unexpired',
            name: 'card_silent_manages_unexpired',
            component: () => import('@/views/card_silent_manages/Unexpired.vue'),
            meta: { permission: 'card_silent_manages' }
          },
          {
            path: 'card_silent_manages_expired',
            name: 'card_silent_manages_expired',
            component: () => import('@/views/card_silent_manages/Expired.vue'),
            meta: { permission: 'card_silent_manages' }
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/customer_service_manages/ShowTab.vue'),
        meta: { title: '售后管理' },
        children: [
          {
            path: 'customer_service_manages_issue',
            name: 'customer_service_manages_issue',
            component: () => import('@/views/customer_service_manages/Issue.vue'),
            meta: { permission: 'customer_service_manages' }
          }
        ]
      }
    ]
  }
]
